.back-to-top {
  display: block;
  background-color: $primary;
  background: url(../img/icons/arrow-top.svg) center center no-repeat;
  position: fixed;
  bottom: 2.5rem;
  right: 2.5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transform: translateY(25px);
  opacity: 0;
  z-index: 20;
  transition: all .3s;
  border: 0;

  @include media-breakpoint-up(md) {
    bottom: 5rem;
    right: 5rem;
  }

  &.active {
    opacity: 1;
    transform: translateY(0);
    transition: all .3s;

    &:hover, &:focus {
      transform: translateY(-15px);
      transition: all .3s;
    }
  }
}