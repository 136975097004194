/*
    Flyer Section
*/

.storeInfo {
  padding: 0;
  margin: 0;

  @media only screen and (min-width:992px) {
    gap: 1rem;}
}

.form-control {
  &.address-locator {
    overflow-y: scroll;
    margin-bottom: 1rem;
  }
}

#selectedStorePhone {
  margin-left: .2rem;
}

#flyer-iframe-container {
  #tc-flyer-iframe {
    width: 100%;
    height: 650px;
  }
}

.flyer-full-page {
  .site-header, .site-footer, .storeInfo{
    display: none !important;
  }
  #flyer-iframe-container, .container {
    max-width: 100%;
    padding: 0 !important;
    margin: 0 !important;

    #tc-flyer-iframe {
      height: 100vh;
      border: none;
      width: 100%;
    }
  }
  .layout--mini-template > *:not(#content-flyer) {
    display: none;
  }
}