/*
    Recipes Section
*/

.recipes {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	background: url( /dist/img/recipes/bg-recette-768.jpg ) center center no-repeat;
	background-size: cover;
	padding: 60px 0;
	height: 1px;
	min-height: 550px;
	text-align: center;

	@include media-breakpoint-up(lg) {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		background: url( /dist/img/recipes/bg-recette-1920.jpg ) center center no-repeat;
		background-size: cover;
		padding: 60px 0;
		height: 1px;
		min-height: 550px;
		text-align: center;
	}

	.btn {
		padding-left: 3rem;
	}

	.r__title {
		font-size: 40px;
		margin: 0 auto;
		padding-bottom: 1.5rem;
		max-width: 700px;

		+ p {
			margin-top: 0;
		}
	}

	.r__description {
		font-size: 24px;
	}

}
