.text-large {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-light-font {
  font-family: $f-roboto-light;
}

.text-strong {
  font-family: $f-roboto-bold;
}