
/*
    Accessbility
*/

// Go to content

.skip-main {

	&:focus {
		border: dotted 1px $gray-500;
		clip: inherit;
		display: block;
		height: inherit;
		left: 5px;
		overflow: visible;
		padding: 5px;
		top: 5px;
		width: auto;
	}
}