/*
    Store address header
*/

.store__selection {

	.ss__address {
		color: rgba( black, 0.5 );
		font-size: 0.9rem;

		a {
			color: $primary;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.change-shop {
		padding-top: 10px;
	}

	.ss_infos {
		width: calc(100% - 55px);
		min-height: 45px;
		padding-right: 0.5rem;
	}
}