.form-search {

	input {
		background-color: darken( $primary, 10% );
		border: 0;
		border-radius: 2rem 0 0 2rem;
		color: white;

		&::placeholder {
			color: white;
		}

		&:active {

			&::placeholder {
				color: $primary;
			}
		}
	}

	.btn {
		background-color: darken( $primary, 15% );
		border: 0;
		border-radius: 0 2rem 2rem 0;
		display: flex;
		justify-content: center;

		&:hover {
			background-color: darken( $primary, 30% );
		}

		img {
			height: 1.2rem;
			margin-left: 0.25rem;
			margin-right: 0.5rem;
			width: 1.2rem;
		}
	}
}