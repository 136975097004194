.promotion-iframe {
  background-color: rgb(255, 255, 255);
  min-height: 1100px;
  margin-left: 0;
  width: 100%;
}

.containerform{
margin-top: 40px;
}

.legalcontest {
    font-size: 11px;
    font-family: arial, sans-serif;
    max-width: 1050px;
    margin: 25px;
    padding-top: 30px;
}

.legalcontest a {
    color: #000;
    text-decoration: underline;
    font-weight: bold;
}