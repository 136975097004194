.site-alert-banner {
    background-color: black;
    padding: 1.5rem;
    z-index: 2;

    p {
        color: white;
        text-align: center;
        padding: 0;
        margin: 0;
        font-size: 15px;

        a {
            color: white;
            text-decoration: underline;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
}