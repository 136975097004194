$f-fallback-heading: 'serif';
$f-fallback-content: 'sans-serif';

$f-domine-bold: 'Domine-Bold', $f-fallback-heading;

$f-sentinel-light: 'Sentinel-Light', $f-fallback-content;
$f-sentinel-medium: 'Sentinel-Medium', $f-fallback-content;
$f-sentinel-bold: 'Sentinel-Bold', $f-fallback-content;
$f-sentinel-black: 'Sentinel-Black', $f-fallback-heading;

$f-skolarlatin-regular: 'SkolarLatin-Regular', $f-fallback-heading;
$f-skolarlatin-extrabold: 'SkolarLatin-Extrabold', $f-fallback-content;

$f-roboto-light: 'robotolight', $f-fallback-content;
$f-roboto-regular: 'roboto', $f-fallback-content;
$f-roboto-medium: 'robotomedium', $f-fallback-content;
$f-roboto-bold: 'robotobold', $f-fallback-content;
$f-roboto-black: 'robotoblack', $f-fallback-content;