.heading-large {
  font-family: $f-sentinel-black;
  font-size: 2.5rem;
  line-height: 2.875rem;
}

.heading-medium {
  font-family: $f-domine-bold;
  font-size: 2rem;
  line-height: 2.4rem;
}

.heading-regular {
  font-family: $f-roboto-medium;
  font-size: 1.5rem;
  line-height: 2rem;
}