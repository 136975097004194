.our-offer {
  .oo__title {
    .jumbotron {
      background-color: $white;
    }

    .img-thumbnail-link {
      p {
        color: $black;
      }
    }
  }

  .oo__title__text {
    @include media-breakpoint-up(md) {
      margin: auto;
      width: 60%;
    }
  }

  .oo__wine__intro {
    position: relative;
    z-index: 0;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 100vw;
      height: 240px;
      background: linear-gradient(180deg, $white 0%, $gray-200 100%);
      transform: translateX(-50%);
      z-index: -1;

      @include media-breakpoint-up(lg) {
        bottom: 15%;
      }

      @include media-breakpoint-up(xl) {
        bottom: 20%;
      }
    }
  }

  .oo__wine__pastilles__wrapper {
    .oo__wine__pastilles {
      &--red-pink {
        .oo__wine__pastilles__scale__gradient {
          background: linear-gradient(180deg, #E4B5C7 0%, #F08A00 34.66%, #E2002B 64.89%, #721735 100%);
        }
      }

      &--white {
        .oo__wine__pastilles__scale__gradient {
          background: linear-gradient(180deg, #E1E79E 0%, #FFE800 49.18%, #A2A7CA 100%);
        }
      }

      &--other {
        .oo__wine__pastilles__scale__gradient {
          background: linear-gradient(180deg, #91CDC3 0%, #B3DBF2 100%);
        }

        .oo__wine__pastilles__scale__label:last-child {
          min-height: 65px;
        }
      }

      &--red-pink, &--white {
        .oo__wine__pastilles__scale__label:last-child {
          html[lang="en"] & {
            min-height: 110px;
          }

          html[lang="fr"] & {
            min-height: 85px;
          }
        }
      }
    }

    .oo__wine__pastilles__scale__gradient {
      flex: 1 1 100%;
      margin-left: 50%;
      width: 2px;
    }

    .oo__wine__pastilles__scale__label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 1rem 0;
      text-transform: uppercase;
      white-space: nowrap;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
    }

    img {
      max-width: 180px;
    }

    .oo__wine__pastille__type {
      display: inline-block;
      position: relative;
      padding-left: 2rem;
      text-transform: uppercase;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: url(/dist/img/wine/tag.svg) center center no-repeat;
        width: 24px;
        height: 24px;
      }
    }
  }

  .oo__wine__pastille__text {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }

  .saq-legal-section {
    padding-left: 0;

    @include media-breakpoint-up(md) {
      padding-left: 6rem;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 18rem;
    }

    @include media-breakpoint-up(xl) {
      padding-left: 21.5rem;
    }
  }

  .logo-saq {
    min-width: 1px;
    width: auto;
    height: 65px;
  }
}


#content div .row {
  margin-bottom: 15px;
}
#content h5 {
  margin-bottom: 0rem !important;
}

.container .encadre {
  border: solid 1px;
  padding: 15px;
  }

  .container .text-bullet {
    font-size: 0.9rem;
}
.container .list-bullet-blue {
    list-style: none;
}

.container .list-bullet-blue li {
    position: relative;
    vertical-align: top;
    padding-bottom: 15px;
}

.container .list-bullet-blue li::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #0272bc;
    display: block;
    position: absolute;
    left: -20px;
    top: 5px;
}

@media (max-width: 768px) {
  .container {
    h1 {
        font-size: 2rem !important;
    }
    h2 {
        font-size: 1.5rem !important;
    }
    h5 {
        font-size: 1rem !important;
    }
  }

  #content {
      font-size: 14px;

      img {
        margin-bottom: 15px;
    }
  }
}

/*ajout style pour produit dici*/
#header-lp-produit-qc{
height: 55vh;
@include media-breakpoint-down(md) {
  height: 350px;
}
}

#header-lp-produit-qc .complice-image{
  margin-top: 0;
}

.boxshadow {
  -webkit-box-shadow: 0px 0px 17px -1px rgba(0, 01, 10, 0.1);
  -moz-box-shadow: 0px 0px 17px -1px rgba(0, 01, 10, 0.1);
  box-shadow: 0px 0px 17px -1px rgba(0, 01, 10, 0.1);
  margin: 40px 25px;
  background-color: #fff;
  padding: 25px;
  position: relative;
}

.boxshadow .col .row .col-lg-3 img{
margin: 0 auto;
}

.rightpush {
  position: relative;
  right: 5rem;
  width: 155px;
  height: auto;
  margin: 0 auto;
  text-align: center;
  display: block;
}

@media screen and (max-width: 768px) {

  .heads2 {
      text-align: center !important;

  }

  .heads {
      margin-bottom: 0 !important;
  }

  .botmar {
      text-align: center !important;

  }

}


@media screen and (min-width: 1025px) {
  .produitici {
      max-width: 100%;
  }

  .rightpush {
      position: relative;
      right: 164px;
      width: 250px;
      height: auto;
  }

  .prod-qc-max-lg{
    max-width: 12rem;
   }
  
}

@media screen and (max-width: 1024px){
  .prod-qc-max-lg{
    max-width: 12rem;
   }
  }

  @media screen and (max-width: 980px){
    .rightpush {
      right: 0;
    }
    }

@media screen and (max-width: 768px){
    .prod-qc-max-lg{
      max-width: 10rem;
     }
    }