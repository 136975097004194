.site-main-navigation {

	.navbar-toggler {
		border: 0;

		span {
			align-items: center;
			display: flex;
			font-size: 1rem;
			text-transform: uppercase;
		}

		i {
			font-size: 1.5em;
			margin-left: 0.5rem;
		}
	}

	.smn__list {
		flex-grow: 1;

		.smn__list__link {
			color: rgba( $white, 1 );
			font-size: 1.1rem;
			text-transform: uppercase;
		}
	}

	.smn__list--second {

		.smn__list__link--second {
			color: rgba( $white, 1 );
			font-size: 1rem;
		}
	}

	hr {
		width: 100%;
	}
}