
/*
    Site main navigation
*/

.site-left-navigation {

	.nav-item,.dropdown-menu {
		width: 100%;
	}

	.dropdown {

		.dropdown-item {
			padding: 0.5rem 1.5rem;
			white-space: initial;
		}
	}
}