
/*
   jumbotron
*/


.jumbotron {
	background-position: center;
	background-size: cover;
	position: relative;

	&.recipes-title {
		background: url('/dist/img/recipes/bg-banniere-fouet.jpg') top left repeat;
		background-size: 100px 100px;
		background-position: 0px -10px;
	}

	.page__content--product & {

		&::before {
			background: linear-gradient( rgba( black, 0 ), rgba( black, 0.8 ) );
			bottom: 0;
			content: ' ';
			display: block;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}
