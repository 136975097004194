.info--magasin {

    .magasin__header {

        p {
            font-family: $f-roboto-light;
            font-size: 1.4rem;
            line-height: 1.4;

            span {
                font-size: 1rem;
                display: block;
                margin-top: 2rem;
                font-style: italic;
            }
        }
    }

    .magasin__details {
        background-color: $primary;
        color: white;

        h2 {
            margin-bottom: 2rem;
            @include media-breakpoint-up(md) {
                max-width: calc(50% - 2rem);
                float: left;
            }
        }

        p {
            font-size: 1.4rem;
            line-height: 1.4;
            font-family: $f-roboto-light;
            margin-bottom: 1.7rem;
            clear: left;
            margin: 0 0 2rem 0;
        }

        img {
            max-width: 100%;
            display: block;

            @include media-breakpoint-up(md) {
                width: 100%;
            }
        }
        .slick-one-auto {
            max-width: 100%;
            display: block;

            @include media-breakpoint-up(md) {
                width: 100%;
            }
            img{
                @include media-breakpoint-up(md) {
                    width: 100%;
                }
            }
        }
    }

    .contest--container {

        h2 {
            font-size: 3rem;
        }
        h3 {
            color: $primary;
            margin-bottom: 1.2rem;
            margin-top: 3rem;
        }

        .formio-component-checkbox {
            margin-bottom: 2rem;
        }

        .form-check-label {
            max-width: 40rem;
            line-height: 1.2;
        }

        .form-control {
            border-radius: 0;
            height: 42px;
            padding: 0.5rem 0.75rem;
        }

        .well-container {
            legend {
                display: none;
            }

            .card-body {
                padding: 0;
                display: block;

                @include media-breakpoint-up(md) {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                }
            }

            .left-well-container,
            .right-well-container {
                padding: 0;
                border: 0;
                flex: 0 0 auto;
                width: 100%;

                @include media-breakpoint-up(md) {
                    width: calc(50% - 1rem);
                }

                .form-group {
                    width: 100%;
                }
            }
        }

        .formio-component-submit {
            button {
                border-radius: 0;
                padding: 0.7rem 5rem;
                border: 0;
                font-size: 1.2rem;
                margin: 1rem 0;
            }
        }

        .concorus-richelieu-footer {
            p {
                padding: 1rem 0 0 0;
                font-size: 0.9rem;
            }
        }
    }

    .magasin__services {
        h2 {
            margin-bottom: 3rem;
        }
        h3 {
            color: $primary;
            margin-bottom: 1.2rem;
            margin-top: 3rem;
        }
        a {
            color: black;
            text-decoration: underline;

            &:hover,
            &:focus {
                text-decoration: none;
                color: $primary;
            }
        }
        ul {
            margin-bottom: 2rem;
            font-family: $f-roboto-light;
            font-size: 1.1rem;
            padding-left: 0;
            list-style: none;
        }
        li {
            margin: 0 0 1rem 0;
            position: relative;
            padding-left: 0.8rem;

            &::before {
                content: "";
                width: 0.3rem;
                height: 0.3rem;
                background-color: $primary;
                border-radius: 0.3rem;
                position: absolute;
                left: 0;
                top: 0.6rem;
                
            }
        }
        .services__images--deco {
            display: block;
            margin-bottom: 1.8rem;

            @include media-breakpoint-up(md) {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
            }

            img {
                display: block;
                max-width: 100%;

                @include media-breakpoint-up(md) {
                    max-width: 23%;
                }
            }
        }
    }

    .magasin__footer {
        p {
            font-size: 0.9rem;
        }

        a {
            display: inline-block!important;
            width: auto;
            padding: 1rem 2rem;
            font-size: 1.1rem;
        }

        .magasin--footer__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-up(md) {
                display: block;
            }
        }

        .fb-footer-container {
            .fb-page {
                width: 100%;
                height: 200px;

                span {
                    max-width: 100%!important;
                    width: 100%!important;
                height: 200px!important;
                }

                iframe {
                    top: 0;
                    left: 0;
                    width: 100%!important;
                    height: 100%!important;
                }
            }
        }
    }
}

.formio-modified {
    .alert {
        padding: 2rem 2rem 1rem 2rem;
        
        p {
            display: none;
        }
    }
}