/*
    Newsletter Cta Section
*/

.newsletter-cta {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url(/dist/img/bg-vert-768.jpg);
  background-size: cover;
  padding: 300px 0 100px 0;
  text-align: center;

  @include media-breakpoint-up(sm) {
    padding-bottom: 200px;
    padding-top: 200px;
  }

  @include media-breakpoint-up(xl) {
    background-image: url(/dist/img/bg-vert-1920.jpg);
  }

  .btn {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .newsletter-rectangle {
    display: block;
    background-color: white;
    padding: 20px 20px 40px;
    width: 90%;

    @include media-breakpoint-up(lg) {
      width: 50%;
    }

    .newsletter-image {
      max-height: 100px;
    }
  }
}

.nc__title {
  font-size: 40px;
  margin: 0 auto;
  font-family: $f-sentinel-bold;

  + p {
    margin-top: 0;
  }
}

.nc__description {
  font-size: 16px;
}

.modal-legal {
  color: $gray-700;
  font-size: 0.8rem;
  margin: 1rem 0;
  text-align: center;
}

.DialogInsightFormInput {
  border: 1px solid $gray-200;
  border-radius: 0.25rem;

  &:not([type="checkbox"]) {
    width: 100%;
  }

  &.DialogInsightFieldInError {
    border-color: $danger;
  }
}

.DialogInsightFormSubmit {
  display: flex;
  justify-content: center;
}