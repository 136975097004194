/*
* Recipe detail page
*/

.recipe-details {
	background-color: inherit;
}

.rd__information {
	.lead {
		font-family: $f-roboto-regular;
	}
}

.rd__information--intro {
	@media (max-width: 992px) {
		padding-top: 1.5rem
	}

	p {
		margin-bottom: 0rem;
		padding-bottom: 1.5rem;
		padding-top: 1.5rem;
	}
}

.related-recipe-list {
	padding-bottom: 1rem;
}

.rd__informationn--preparation {
	counter-reset: number;

	.list-group-item {
		align-items: stretch;
		display: flex;

		&::before {
			content: counter(number);
			counter-increment: number;
			display: block;
			padding-right: 0.5rem;
		}
	}
}
	.btn-recipe-info {
		display: flex;
		max-width: 500px;
	}

	.btn-recipe-info button {
		flex: 1;
		text-align: center;
		padding: 15px;
		border: 0.1rem solid #ffffff;
		cursor: pointer;
		color: #a7a7a7;
		background: rgb(214,214,214);
		background: linear-gradient(180deg, rgba(214,214,214,1) 0%, rgba(255,255,255,1) 50%, rgba(230,230,230,1) 100%);
		text-transform: uppercase
	}
	.btn-recipe-info button:hover, .btn-recipe-info button:focus {
		color: black;
		background: linear-gradient(180deg, rgba(214,214,214,0.4) 0%, rgba(255,255,255,1) 50%, rgba(230,230,230,0.4) 100%);
	}

	.btn-recipe-info button.btn-recipe-info-active {
		background: transparent;
		background-color: #3070b7;
		color: white;
		font-weight: bold;
		outline: none;
		box-shadow: none;

		span {
			text-decoration-color: white;
		}
	}

	.btn-recipe-info .btn-info-ing {
		border-radius: 20px 0 0 20px;
		border-right: 0;
	}

	.btn-recipe-info .btn-info-prep {
		border-radius: 0 20px 20px 0;
		border-left: 0;
	}

	.recipe-ingredients li::before {
		content: "\2022";
		color: black;
		font-weight: bold;
		display: inline-block;
		width: 1em;
		margin-left: -1em;
	}

	.recipe-preparation ol
	{
		list-style: none;
		margin-left: 0;
	}

	.recipe-preparation li
	{
		counter-increment: custom;
		font-weight: bolder;
		margin-bottom: 0rem;
		padding-bottom: 1.5rem;
		padding-top: 1.5rem;
	}

    .recipe-preparation ol li:before
	{
		content: counter(custom) "\00a0 \00a0";
	}

    .recipe-preparation li:first-child {
		counter-reset: custom;
	}

	.recipe-preparation li span {
		font-weight: normal;
	}