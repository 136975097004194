
/*
    Page header
*/
.btn-header{
	position: absolute;
	bottom: 9rem;
}
@include media-breakpoint-down(lg) {
	.btn-header{
		bottom: 8.5rem;
	}
}

@include media-breakpoint-down(md) {
	.btn-header{
		bottom: 8rem;
	}
}

@include media-breakpoint-down(sm) {
	.btn-header{
		bottom: 1.5rem;
	}
}

.page__header {
	background-color: #0072BC;
	height: 70vh;
	max-height: 600px;
	min-height: 600px;
	padding-bottom: 40px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	background-repeat: no-repeat;
	background-position: center !important;

	@include media-breakpoint-down(lg) {
		background-position: right top !important;
	}

	@include media-breakpoint-down(md) {
		/*transform: translateY(20px);*/
		background-position: right top !important;
		/*background-position: calc(50% - 558px) top !important;*/
		/*background-position: calc(25% - 238px) top !important;*/
		
	}

	@include media-breakpoint-down(sm) {
		height: 350px;
		margin-top: -21px;
		min-height: 300px;
		padding-bottom: 40px;
		position: relative;
		background-position: right top !important;
		/*background-position: calc(50% - 248px) top !important;*/
		/*background-position: calc(25% - 118px) top !important;*/
	}

	@media (max-width: 375px) {

		.page__header {
		max-height: 466px;
		min-height: 437px;
		}

	}


	.custom-hp-btn {
	    position: absolute;
        bottom: 0;
        margin-bottom: 129px;
        box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.2);
        left: calc(50% - 100px);

        @include media-breakpoint-down(sm) {
        		margin-bottom: 99px;
        	}
	}

}

a.btn-lg.btn.btn-secondary.fp__main-button.btn-MP::after {
    display: none !important;
}

a.btn-lg.btn.btn-secondary.fp__main-button.btn-MP {
	position:absolute;
	bottom:55px;
	transform:translateX(-50%);
    padding: 0.5rem 1rem !important;
	
	@include media-breakpoint-down(lg) {
		bottom:47px;
	}
	@include media-breakpoint-down(md) {
		bottom: 46px;
        font-size: 12px;
	}
	@include media-breakpoint-down(sm) {
		bottom: 65px;
        font-size: 1.25rem;
	}
	@include media-breakpoint-down(xs) {
		bottom: 53px;
        font-size: 12px;
	}
}
.complice-image {
	margin-right: 0rem;
    /* mettre margin-right à 0 pour centrer */
	margin-top: -12rem;
	width: 45rem;

	@include media-breakpoint-down(lg) {
		margin-right: 0;
        /* mettre margin-right à 0 pour centrer */
		margin-top: -12rem;
		width: 43rem;
	}

	@include media-breakpoint-down(md) {
		margin-right: 0;
        /* mettre margin-right à 0 pour centrer */
		margin-top: -12rem;
		width: 40rem;
	}

	@include media-breakpoint-down(sm) {
        /* mettre margin-right à 0 pour centrer */
		margin-right: 0;
		margin-top: -1rem;
		width: 20rem;
	}
  }

  .titre-header-image {
	margin-right: 8rem;
    /* mettre margin-right à 0 pour centrer */
	margin-top: -9rem;
	width: 33rem;

	@include media-breakpoint-down(lg) {
		margin-right: 1rem;
        /* mettre margin-right à 0 pour centrer */
		margin-top: -7rem;
		width: 23rem;
	}

	@include media-breakpoint-down(md) {
		margin-right: 0;
        /* mettre margin-right à 0 pour centrer */
		position: absolute;
		top:8.75rem;
		margin-top: -7rem;
		width: 22rem;
	}

	@include media-breakpoint-down(sm) {
        /* mettre margin-right à 0 pour centrer
		margin-top: 3.5rem; */
		margin-right: 0;
		width: 12rem;
	}
  }

@include media-breakpoint-up(md) {

	.page__header {
		padding-bottom: 0;
		margin-bottom: -100px;
		min-height: 370px;
	}
}

/*
    Pge header heading block
*/

.page__header__heading-block {
	background: rgba( 255, 255, 255, 0.9 );
	box-shadow: 0 0 0 14px rgba( 209, 224, 229, 0.9 );
	left: 50%;
	margin: 0 auto;
	max-width: 500px;
	padding: 40px;
	position: absolute;
	text-align: center;
	top: 80px;
	transform: translateX( -50% );
	width: 90%;
}

.page__header__heading-block__title {
	font-family: $f-domine-bold;
	font-size: 2.5rem;
	margin-bottom: 1rem;
}
