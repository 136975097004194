/*
    Site footer
*/

.site-footer {
  background: $white;
  position: relative;
  justify-self: flex-end;
  margin-top: auto;
}

.site-footer__inner {
  position: relative;
  padding-bottom: 30px;
  padding-top: 30px;

  body:not(.home) & {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $gray-500;
    }
  }
}

.footer__nav {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  width: 100%;
}

.btn-form-newsletter {
    font-size: 14px;
    margin-top: 0;
    @media (max-width: 991px) {
    margin-top: 2rem;
    }
}

.footer__nav__item {
  align-items: center;
  display: flex;
  font-size: 0.9rem;
  padding: 0.5rem 0;
  text-align: center;
  margin-bottom: 0.5rem !important;

  &:not(:last-child):after {
    @include media-breakpoint-up(lg) {
      content: '';
      display: block;
      width: 1px;
      height: 10px;
      background-color: $secondary;
      
    }
  }
}

.footer__nav__item__link {
  @include media-breakpoint-up(lg) {
    border-right: solid 1px $gray-500;
    padding-left: 1rem;
    padding-right: 1rem;

    .footer__nav__item:first-child & {
      padding-left: 0;
    }
  }
}

.copyright {
  color: $gray-600;
  font-size: 0.9rem;
  margin-right: 20px;
  padding: 0;

  @include media-breakpoint-down(lg) {
    margin-right: 0;
    text-align: center;
    width: 100%;
  }
}