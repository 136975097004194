.page__content {
  background-color: #f8f9fa;
  padding: 0;

  .content-big {
    margin: 40px auto 0 auto;
    background-color: #f8f9fa;
    height: auto;
    text-align: center;
    padding: 0 0 35px 0;

    .content-banner {
      max-width: 100%;
      width: 100%;
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    .content-banner-mobile {
      display: block;
      max-width: 100%;
      width: 100%;
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

  }

  .content-big-covid {
    margin: 40px auto 0 auto;
    background-color: #f8f9fa;
    height: auto;
    text-align: center;
    padding: 0 0 0 0;

    .content-banner {
      max-width: 100%;
      width: 100%;
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    .content-banner-mobile {
      display: block;
      max-width: 100%;
      width: 100%;
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
}