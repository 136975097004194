
/*
    Block title
*/

.offer-title {
	box-sizing: border-box;
	margin-bottom: 2.5rem;
	margin-left: 50%;
	transform: translateX( -50% );
	width: 100vw;

	.jumbotron-bg {
		background-color: transparent;
		background-size: cover;
	}
}