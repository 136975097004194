/*
    Site header
*/

.site-header {
	background: $white;
	min-height: 1px;

	.site-logo {
		min-width: 100px;
	}
}